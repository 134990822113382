import React from "react";

//https://s3.us-east-1.wasabisys.com/constructions/static/medship-loading.gif"

const CircularProgress = ({ className }) => {
  // Get the current hostname
  const hostname = window.location.hostname;

  // Determine the image URL based on the hostname
  let imgUrl;
  if (hostname === "medship.health") {
    imgUrl =
      "https://s3.us-east-1.wasabisys.com/constructions/static/medship-loading.gif";
  } else if (hostname === "powderm.health") {
    imgUrl = "https://s3.us-east-2.wasabisys.com/medshipohio/Static/p1.gif";
  } else if (hostname === "femmegen.health") {
    imgUrl =
      "https://s3.us-east-2.wasabisys.com/medshipohio/Static/1-black.gif"; // Fallback for other domains
  } else {
    imgUrl =
      "https://s3.us-east-1.wasabisys.com/constructions/static/medship-loading.gif"; // Fallback for other domains
  }

  return (
    <div className={`loader ${className}`}>
      <img src={imgUrl} alt="loader" />
    </div>
  );
};

export default CircularProgress;
